import React from 'react'
import 'twin.macro'

export default function ExternalLink({ href, children, ...rest }) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      tw="font-semibold text-blue hover:text-grey-2 focus:outline-none focus-visible:(ring ring-blue ring-opacity-50)"
      {...rest}
    >
      {children}
    </a>
  )
}
